import React, { useContext } from 'react';
//import { StaticQuery, graphql } from "gatsby"
import { Link, navigate, useStaticQuery, graphql } from 'gatsby';

import PubSub from 'pubsub-js';
import { FiltersContext } from '../components/Layout';
// import Burger from './ui/Burger';
import FiltersHeaderNav from './FiltersHeaderNav';
import { useEffect } from 'react';

const refreshIcon = require('../images/refresh.svg');
const mailIcon = require('../images/mail.svg');

const query = graphql`
  query {
    menu: prismicMenu {
      data {
        menu {
          icon {
            url
          }
          # page {
          #   document {
          #     ... on PrismicPage {
          #       uid
          #       data {
          #         title {
          #           text
          #         }
          #       }
          #     }
          #   }
          # }
          label
          link {
            url
            link_type
            target
            document {
              ... on PrismicPage {
                uid
                data {
                  title {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Header = ({ template }) => {
  const { menu } = useStaticQuery(query);
  const _FiltersContext = useContext(FiltersContext);
  const { filters, dispatch } = _FiltersContext;

  useEffect(() => {
    document.addEventListener('keydown', _onKey);

    return () => document.removeEventListener('keydown', _onKey);
  });

  const _onKey = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (filters.length) {
        PubSub.publish('SEARCH', filters);
        navigate('/liste');
      }
    }
  };
  // console.log(menu)
  const isFiltering = filters.length > 0;

  return (
    <header className="strong">
      <div className="row between-xs">
        <div className="col-md-3 col-xs-6">
          <div className="site-name fM ">
            <Link to="/">
              Atlas des
              <br />
              Régions
              <br />
              Naturelles
              <br />
            </Link>
          </div>

          {template !== 'liste' && (
            <nav className="hidden-xs">
              <ul>
                {menu.data.menu.map((li, i) => (
                  <li key={i}>
                    {/* <Link to={`/${li.page.document.uid}`} className="ttu">
                        {li.icon && li.icon.url
                          ? <img src={li.icon.url} alt={li.page.document.data.title.text} width="14"/>
                          : <span className="title">{li.page.document.data.title.text}</span>
                        }
                      </Link> */}
                    {li.link.link_type === 'Document' ? (
                      <Link to={`/${li.link.document.uid}`} className="ttu">
                        {li.icon && li.icon.url ? (
                          <img src={li.icon.url} alt={li.label} width="14" />
                        ) : (
                          <span className="title">{li.label}</span>
                        )}
                      </Link>
                    ) : (
                      <a
                        href={li.link.url}
                        rel="noreferrer, noopener"
                        target={li.link.target}
                      >
                        {li.label}
                      </a>
                    )}
                  </li>
                ))}
                <li className="">
                  <a href="mailto:archive.atlasrn@gmail.com">
                    <i>
                      <img src={mailIcon} alt="E-mail" height="14" />
                    </i>
                  </a>
                </li>
              </ul>
            </nav>
          )}
        </div>

        <div className="col-md-3 col-xs-12 col-filters-nav">
          {(template === 'home' || template === 'liste') &&
            filters.length > 0 && <FiltersHeaderNav input={filters} />}
        </div>

        <div className="col-xs-6 ">
          <div className="inner h100">
            <div className="row h100">
              <div className="col-md-9 "></div>
              <div className="col-md-3 col-xs-12 text-left h100">
                <ul className="actions ">
                  {(template === 'home' || template === 'liste') && (
                    <>
                      <li>
                        <Link
                          to="/liste"
                          onClick={() => PubSub.publish('SEARCH', filters)}
                          className={isFiltering ? 'rouge' : ''}
                        >
                          <i>⟶</i> RECHERCHER
                        </Link>
                      </li>
                      {isFiltering && (
                        <li
                          className="curp btn ttu"
                          onClick={() => dispatch({ type: 'RESET' })}
                        >
                          <i>
                            <img src={refreshIcon} alt="refresh" height="10" />
                          </i>{' '}
                          rafraîchir
                        </li>
                      )}
                    </>
                  )}
                  {template !== 'home' && (
                    <li className="">
                      <Link to="/">
                        <i>⟵</i> REVENIR
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Burger /> */}
    </header>
  );
};

export default Header;
