import React from 'react';
import { Link, navigate, useStaticQuery, graphql } from 'gatsby';
const mailIcon = require('../images/mail.svg');

const query = graphql`
  query {
    menu: prismicMenu {
      data {
        menu {
          icon {
            url
          }
          label
          link {
            url
            link_type
            target
            document {
              ... on PrismicPage {
                uid
                data {
                  title {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Footer = () => {
  const { menu } = useStaticQuery(query);
  // console.log(menu);
  return (
    <footer className="xs-only">
      footer
      <div className="x xjb">
        <div className="col w70">
          <nav className="">
            <ul className="x xjb xac">
              {menu.data.menu.map((li, i) => (
                <li key={i}>
                  {li.link.link_type === 'Document' ? (
                    <Link to={`/${li.link.document.uid}`} className="ttu">
                      {li.icon && li.icon.url ? (
                        <img src={li.icon.url} alt={li.label} width="14" />
                      ) : (
                        <span className="title">{li.label}</span>
                      )}
                    </Link>
                  ) : (
                    <a
                      href={li.link.url}
                      rel="noreferrer, noopener"
                      target={li.link.target}
                    >
                      {li.label}
                    </a>
                  )}
                </li>
              ))}
              <li className="">
                <a href="mailto:archive.atlasrn@gmail.com">
                  <i>
                    <img src={mailIcon} alt="E-mail" height="14" />
                  </i>
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="col">
          <div className="copyright">© {new Date().getFullYear()} </div>
          {/* <p>Made with ❤ by the <a href="ahmedghazi.com" target="_blank">a_e_a_i_</a></p> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
