import { ApolloClient, InMemoryCache } from '@apollo/client';
import fetch from 'isomorphic-fetch';

const cache = new InMemoryCache();

export const client = new ApolloClient({
  fetch,
  uri: process.env.GATSBY_API_URL,
  cache,
});
