export function fileNameByUrl(url) {
  const decoded = decodeURIComponent(url);
  return decoded.substring(decoded.lastIndexOf('/') + 1);
}

// In src/prismic-configuration.js
export const linkResolver = (doc) => {
  // URL for a category type
  if (doc.type === 'project') {
    return `/project/${doc.uid}`;
  }
  // URL for a product type
  // if (doc.type === 'product') {
  //   return `/product/${doc.uid}`
  // }
  // URL for a page type
  // if (doc.type === 'homepage') {
  //   return `/`
  // }
  // Backup for all other types
  return '/';
};

// export const trim = (doc) => {

// }

export const dynamicSort = (property) => {
  // console.log(property);
  let sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }
  return (a, b) => {
    // console.log(a[property], b[property])
    const result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
};

export const flatten = (array) => {
  var result = [];
  array.forEach(function (a) {
    result.push(a);
    if (Array.isArray(a.children)) {
      result = result.concat(flatten(a.children));
    }
  });
  return result;
};

export const recursiveExtractByKey = (array, key) => {
  let result = [];
  let exclude = [];

  array.forEach((a, i) => {
    if (a[key] && a[key] !== '') {
      result.push(a);
      const _i = array.indexOf(a);

      exclude.push(_i);
    }
  });

  if (exclude.length) {
    while (exclude.length) {
      array.splice(exclude.pop(), 1);
    }
  }

  array.forEach((a, i) => {
    if (a && Array.isArray(a.children)) {
      a.children.sort(dynamicSort('ordering'));
      result = result.concat(recursiveExtractByKey(a.children, key));
    }
  });

  return result;
};

export const recursiveExtractByLabel = (array, label) => {
  let result = [];
  // console.log(array)
  array.forEach((a, i) => {
    // console.log(a.label)
    if (a.label === label) {
      // console.log(a.label, label)
      result.push(a);
      array.splice(i, 1);
      // delete a
    }
    if (Array.isArray(a.children)) {
      result = result.concat(recursiveExtractByLabel(a.children, label));
    }
  });
  return result;
};

export const recursiveRemoveIfHidden = (array) => {
  let result = [];
  array.forEach((a, i) => {
    // console.log(i, key, a.label, a[key])
    if (a.hidden) {
      array.splice(i, 1);
    } else {
      result.push(a);
    }
    if (Array.isArray(a.children)) {
      result = result.concat(recursiveExtractByKey(a.children, key));
    }
  });
  return result;
};

export const recursiveAddKeyVal = (array, key, val) => {
  array.forEach((a, i) => {
    // console.log(a.children)
    a[key] = val;

    if (Array.isArray(a.children)) {
      recursiveAddKeyVal(a.children, key, val);
    }
  });
};

export const flattenObject = (ob) => {
  var toReturn = {};

  for (var i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if (typeof ob[i] == 'object') {
      // var flatObject = flattenObject(ob[i]);
      toReturn[i] = ob['label'];
    }
  }
  return toReturn;
};

export const getColorByAncestor = (obj) => {
  /*
  Rouge : régions
  Bleu : thèmes
  Vert : cadrage, année, saison//"01-DONNEES|01 ANNEE|2017"
  Jaune : séries
$rouge: #e52321;
$vert: #5eb98f;
$bleu: #2c4a9a;
$jaune: #f6a13d;
  */
  //  console.log(obj)
  const labelLong = obj.labelLong;
  if (!labelLong) return '';
  return '';
  if (labelLong.search(/\b(?:donnees|données)\b/gi) > -1) return '#5eb98f';
  else if (labelLong.search(/\b(?:description)\b/gi) > -1) return '#2c4a9a';
  else if (labelLong.search(/\b(?:séries|series)\b/gi) > -1) return '#f6a13d';
  else if (labelLong.search(/\b(?:regions-naturelles)\b/gi) > -1)
    return '#e52321';
  else return '#2c4a9a';
};

export const getExcerpt = (str, limit) => {
  const fullText = str;
  let shortText = str;
  shortText = shortText.substr(0, shortText.lastIndexOf(' ', limit));
  return {
    fullText: fullText,
    shortText: shortText,
  };
};
