import React, { useContext } from 'react';
import { FiltersContext } from './Layout'
import { getColorByAncestor } from '../core/utils'

const FiltersHeaderNav = () => {
  const _FiltersContext = useContext(FiltersContext)
  const { filters, dispatch } = _FiltersContext
// console.log(filters)
  
  // const getColorByType = (filter) => {
  //   // console.log(filter)
  //   return 'bleu'
  // }

  const _colorize = (target, color) => {
    if(!color)return
    target.style.background = color
    target.style.color = "white"
    target.style.borderColor = color
  }

  const _resetColorize = (target, color) => {
    if(!color)return
    target.style.background = "white"
    target.style.color = color
    target.style.borderColor = color
  }

  const _getColor = (filter) => {
    return filter.color
    ? filter.color
    : getColorByAncestor(filter)
    
  }

  const _remove = (filter) => {
    // console.log("_remove")
    dispatch({type: "REMOVE", payload: filter})
  }

  return (
    <ul className="filters-header-nav x xw">
      {filters.map((filter, i) => (
        <li key={i}>
          <button 
          className={`outline`}  
          style={{color: _getColor(filter)}}
          onMouseEnter={(e) => _colorize(e.target, _getColor(filter))}
          onMouseLeave={(e) => _resetColorize(e.target, _getColor(filter))}
          onClick={() => _remove(filter)}>
            <span>{filter.label}</span>
            <span className="chip">×</span>
          </button>
        </li>
      ))}
    </ul>
  );
};

export default FiltersHeaderNav;