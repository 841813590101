// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-actus-jsx": () => import("./../../../src/templates/Actus.jsx" /* webpackChunkName: "component---src-templates-actus-jsx" */),
  "component---src-templates-default-jsx": () => import("./../../../src/templates/Default.jsx" /* webpackChunkName: "component---src-templates-default-jsx" */),
  "component---src-templates-home-jsx": () => import("./../../../src/templates/Home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-infos-jsx": () => import("./../../../src/templates/Infos.jsx" /* webpackChunkName: "component---src-templates-infos-jsx" */),
  "component---src-templates-liste-jsx": () => import("./../../../src/templates/Liste.jsx" /* webpackChunkName: "component---src-templates-liste-jsx" */)
}

