import React, { createContext, useReducer, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby";
import PubSub from 'pubsub-js';
import { throttle } from 'throttle-debounce';
import Header from "./Header"
import Footer from "./Footer"

import { ApolloProvider } from '@apollo/client';
import {client} from "./ApolloClient"

const WrapperContext = createContext()
const FiltersContext = createContext();

const initialState = []
const reducer = (state, action) => {
  switch (action.type) {
    case 'ADD': 
      return [...state, action.payload];
    case 'REMOVE': return [...state.filter(el => el.id !== action.payload.id)];
    case 'RESET': return [];
    default: throw new Error('Unexpected action');
  }
};

const query = graphql`
  query {
    settings: prismicSettings {
      data {
        title {
          text
        }
        description {
          text
        }
        image {
          url
        }
      }
    }
  }
`

const Layout = ({ children, pageContext: { template } }) => {
  const [filters, dispatch] = useReducer(reducer, initialState);
  const { settings } = useStaticQuery(query)

  useEffect(() => {
    document.addEventListener("scroll", throttle(250, _onScroll))
    return () => document.removeEventListener("scroll", throttle(250, _onScroll))
  }, [])

  useEffect(() => {
    const filtersId = filters.map(el => el.id)
    PubSub.publish("FILTERS", filtersId)

    //reset header hidden on scroll
    document.body.classList.remove("down")
  }, [filters])

  let prevScrollTop = 0
  const _onScroll = () => {
    if (typeof window === `undefined`) return
    if (window.pageYOffset > prevScrollTop) {
      document.body.classList.add("down")
    } else {
      document.body.classList.remove("down")
    }
    prevScrollTop = window.pageYOffset
  }

  return (
    <ApolloProvider client={client}>
        <WrapperContext.Provider value={{ settings }}>
          <FiltersContext.Provider value={{ filters, dispatch }}>
            <div id="page">
              <Header template={template} />
              <main>
              {children}
              </main>
              <Footer />  
            </div>
          </FiltersContext.Provider>
      </WrapperContext.Provider>
    </ApolloProvider>
    
  )
}

export { WrapperContext, FiltersContext, Layout }


