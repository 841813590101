/* eslint react/prop-types: 0, react/display-name: 0 */
import React from 'react';
import { Layout } from './src/components/Layout';

const _wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

const _onClientEntry = () => {
  const isTouch = 'ontouchstart' in window ? true : false;

  if (isTouch) {
    document.documentElement.classList.add('touch');
  } else {
    document.documentElement.classList.add('no-touch');
  }
};

export { _wrapPageElement, _onClientEntry };
